var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"container categories position-relative"},[(!_vm.loading)?_c('div',[(_vm.$route.params.category)?_c('section',{staticClass:"categories_banner my-1 mb-10",style:({
        ..._vm.getBanner,
        backgroundPosition: 'center',
      })},[_c('div',{staticClass:"c_banner_text"},[_c('p',{staticClass:"mb-5",domProps:{"innerHTML":_vm._s(
            (_vm.blogCategory && _vm.blogCategory.description) ||
              'Lorem ipsum dolor sit amet, consectetur <b>adipiscingeros donec</b> '
          )}}),(_vm.blogCategory && _vm.blogCategory.banner_button_visible)?_c('a',{staticClass:"banner_btn py-2 px-4",style:({
            color: _vm.blogCategory.banner_button_text_color || '#666666',
            backgroundColor: _vm.blogCategory.banner_button_color || '#fff',
          }),attrs:{"href":_vm.blogCategory.banner_button_link}},[_vm._v(" "+_vm._s(_vm.blogCategory.banner_button_text || 'SHOP NOW')+" ")]):_vm._e()])]):_vm._e(),_c('section',{staticClass:"d-flex"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"block_left ma-0",attrs:{"sm":"3"}},[_c('h4',[_vm._v("Categories")]),_c('NestedCategories',{attrs:{"categories":_vm.blogCategories}})],1):_vm._e(),_c('v-col',{staticClass:"block_right ma-0",class:{ 'mx-auto': _vm.$vuetify.breakpoint.smAndDown },attrs:{"sm":"9"}},[_c('div',{staticClass:"collections pa-0"},[_c('v-row',_vm._l((_vm.blogs),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"4"}},[_c('CategoryCard',{staticClass:"cursor-pointer",attrs:{"imgSrc":item.photo},nativeOn:{"click":function($event){return _vm.openBlog(item)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"collections_text"},[_c('span',[_vm._v(_vm._s(item.title))])])]},proxy:true}],null,true)})],1)}),1)],1)])],1)],1)],1)]):_c('div',{staticStyle:{"height":"50vh"}},[_c('div',{staticStyle:{"position":"absolute","transform":"translate(-50%, -50%)","left":"50%","top":"50%"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }