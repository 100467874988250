<template>
  <ul class="pa-0">
    <li v-for="item in categories" :key="item.id">
      <router-link
        :to="{
          name: 'Blog categories',
          params: { category: item.url },
        }"
        class="cursor-pointer category_link"
        :class="{
          'category_link--active': $route.params.category === item.url,
        }"
        >{{ item.title }}</router-link
      >
      <nested-categories
        v-if="item.childrens.length"
        :categories="item.childrens"
        class="pl-4"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NestedCategories',
  props: ['categories'],
}
</script>

<style scoped lang="scss">
.category_link {
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
  &.category_link--active {
    text-decoration: underline;
  }
}
</style>
